<template>
    <div
        class="the-home-banner"
    >
        <p class="the-home-banner__message">
            {{ banner?.text }}
        </p>

        <BaseArrowLink
            class="the-home-banner__arrow-link base-arrow-link--dark"
            :element="CraftLink"
            :link="banner?.linkField"
            label-position="right"
            @click="handleBannerClick"
        >
            {{ banner?.linkField?.text }}
        </BaseArrowLink>

        <CraftLink
            class="the-home-banner__arrow"
            :link="banner?.linkField"
            no-prefetch
            @click="handleBannerClick"
        >
            <BaseIcon icon="arrow" />
        </CraftLink>
    </div>
</template>

<script setup>
import { useGtm } from '@gtm-support/vue-gtm';

defineProps({
    banner: {
        type: Object,
        required: true
    }
});

const CraftLink = resolveComponent('CraftLink');

useHead({
    style: () => [
        `
        :root {
            --banner-height: 2.5rem;
        }

        @media only screen and (min-width: 769px) {
            :root {
                --banner-height: 3rem;
            }
        }
        `
    ]
});
function handleBannerClick() {
    const gtm = useGtm();
    gtm.trackEvent({
        /* eslint-disable camelcase */
        event: 'banner_click',
        /* eslint-enable camelcase */
    });
}
</script>

<style lang="less">
.the-home-banner {
    .base-grid-padding(left);
    .base-grid-padding(right);

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-secondary);
    height: var(--banner-height);

    @media @q-md-min {
        justify-content: center;
    }

    .base-button.dn-button {
        .button-clean();
    }
}

.the-home-banner__message {
    .typography-text-sm();
    margin: 0 .5rem 0 0;

    @media @q-md-max {
        margin: 0 2rem 0 0;
    }
}

.the-home-banner__arrow-link.base-arrow-link {
    .typography-label();
    line-height: 1;
    display: none;

    @media @q-md-min {
        display: inline-flex;
    }
}

.the-home-banner__arrow[href] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: .5rem var(--grid-gutter-margin) .5rem 0;
    font-size: 2rem;
    transform: translateX(0);
    transition: transform var(--transition-fast);

    &:hover, &:active {
        transform: translateX(.5rem);
    }

    @media @q-md-min {
        display: none;
    }
}
</style>
